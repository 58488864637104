<template>
	<div>
		<h1>{{ translate('autoship_configuration') }}</h1>
		<div
			:class="{
				'mt-3': !['xs'].includes(windowWidth),
				'mt-2': ['xs'].includes(windowWidth),
			}"
			class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<new-default-address
								:errors-import="errors"
								@onUpdate="updateFormField('address_id', $event)" />
							<new-default-credit-card
								:errors="errors"
								:last-shipping-id="form.address_id"
								@onUpdate="updateFormField('card_id', $event)" />
							<autoship-products-choose
								:errors="errors"
								@updateProducts="updateFormField('products', $event)"
								@updatePeriodicity="updateFormField('autoship_periodicity', $event)"
								@updateDate="updateFormField('next_autoship_date', $event)" />
							<b-row class="my-4">
								<b-col
									v-if="disabledSave"
									class="text-right"
									cols="12">
									<p class="text-danger">
										{{ translate('affilite_fill_info_alert_msg') }}
									</p>
								</b-col>
								<b-col
									class="text-right"
									cols="12">
									<button
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100 my-2' : ''"
										class="btn btn-lg btn-light mr-2"
										@click="goToDashboard">
										{{ translate('configure_later') }}
									</button>
									<button
										:disabled="disabledSave || loading"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
										class="btn btn-lg btn-success b-4 "
										@click="saveAutoshipInfo">
										{{ translate('save') }}
									</button>
								</b-col>
							</b-row>
						</flow-info-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FlowInfoGroup from '@/components/FlowInfo/Group';
import windowSizes from '@/mixins/WindowSizes';
import NewDefaultAddress from '@/views/BecomeAffiliate/components/NewDefaultAddress';
import AutoshipProductsChoose from '@/views/BecomeAffiliate/components/AutoshipProductsChoose';
import NewDefaultCreditCard from '@/views/BecomeAffiliate/components/NewDefaultCreditCard';
import AutoshipInformation from '@/util/AutoshipInformation';
import { AutoshipInformation as AutoshipInformationTranslations } from '@/translations';

export default {
	name: 'FillAffiliateAutoshipInfo',
	messages: [AutoshipInformationTranslations],
	components: {
		NewDefaultCreditCard,
		AutoshipProductsChoose,
		NewDefaultAddress,
		FlowInfoGroup,
	},
	mixins: [windowSizes],
	data() {
		return {
			saveAutoship: new AutoshipInformation(),
			disabledSave: true,
			form: {
				next_autoship_date: null,
				autoship_periodicity: null,
				card_id: null,
				address_id: null,
				products: { },
			},
		};
	},
	computed: {
		errors() {
			try {
				return this.saveAutoship.data.errors.errors;
			} catch (e) {
				return [];
			}
		},
		loading() {
			return !!this.saveAutoship.data.loading;
		},
	},
	watch: {
		form: {
			deep: true,
			handler() {
				this.disabledSave = !this.form.next_autoship_date || !this.form.autoship_periodicity || !this.form.card_id || !this.form.address_id || !Object.keys(this.form.products).length;
			},
		},
	},
	methods: {
		updateFormField(field, value) {
			let newValue = value;
			if (typeof value === 'undefined') {
				newValue = null;
			}
			this.form[field] = newValue;
		},
		goToDashboard() {
			this.$router.push({ name: 'MainHome' });
		},
		saveAutoshipInfo() {
			this.saveAutoship.updateAutoshipInformation(this.form).then(() => {
				const alert = new this.$Alert();
				const options = {
					buttonText: this.translate('ok'),
				};
				alert.success(this.translate('enable_autoship_success'), '', options).then(() => {
					this.$router.push({ name: 'AutoshipInformation' });
				}).catch(() => {});
			});
		},
	},
};
</script>
